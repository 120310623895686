<template>
    <transition name="fade">
        <section
            v-if="visible"
            class="fixed min-h-screen z-50 inset-0 overflow-y-auto scrollbar-none"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
        >
            <div class="fixed inset-0 bg-black/60 backdrop-blur-sm" aria-hidden="true" @click.stop="backgroundHide" />

            <div class="sm:mt-20" @click.stop>
                <div class="relative panel" :class="variation">
                    <font-awesome-icon
                        v-if="canClose"
                        icon="fa-solid fa-xmark"
                        class="absolute top-4 right-4 text-gray-300 hover:text-gray-200 text-xl cursor-pointer"
                        :class="{ 'text-white': variation === 'guide' }"
                        @click="hide"
                    />
                    <slot name="icon" />
                    <slot name="header" />
                    <slot name="body" />
                </div>
            </div>
        </section>
    </transition>
</template>

<script setup lang="ts">
    import { defineExpose, onMounted, ref } from 'vue';

    const { canClose, variation } = defineProps({
        canClose: {
            type: Boolean,
            default: true,
        },
        variation: {
            type: String as () => 'default' | 'guide',
            default: 'default',
        },
    });

    const emit = defineEmits(['escape', 'enter', 'shown', 'hidden']);

    const visible = ref<boolean>(false);

    onMounted(() => {
        window.addEventListener('keydown', (e) => {
            if (!visible.value) return;
            if (e.code === 'Escape') {
                e.preventDefault();

                emitEscape();
            }
        });

        window.addEventListener('keydown', (e) => {
            if (!visible.value) return;

            if (e.code === 'Enter' || e.code === 'NumpadEnter') {
                e.preventDefault();
                emit('enter');
            }
        });
    });

    const emitEscape = () => {
        emit('escape');
    };

    const show = () => {
        visible.value = true;
        emit('shown');
    };

    const hide = () => {
        visible.value = false;
        emit('hidden');
    };

    const backgroundHide = () => {
        if (!canClose || variation === 'guide') return;

        visible.value = false;

        emit('hidden');
    };

    defineExpose({
        hide,
        show,
    });
</script>

<style scoped>
    .guide {
        @apply bg-[#464FEB] mt-40;
    }

    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.1s ease;
    }

    .fade-enter-from,
    .fade-leave-to {
        opacity: 0;
    }
</style>
