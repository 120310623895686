import { IntroductionStep } from '@/types/store/introduction';

export const INTRODUCTION_INIT_STATE = {
    is_flow_active: false,
    proof_ordered: false,
    basic_account_details: IntroductionStep.PENDING,
    verify_creator_account: IntroductionStep.PENDING,
    create_first_page: IntroductionStep.PENDING,
    edit_your_page: IntroductionStep.PENDING,
    complete_page: IntroductionStep.PENDING,
    first_page_complete: IntroductionStep.PENDING,
    first_page_block: IntroductionStep.PENDING,
    create_first_release: IntroductionStep.PENDING,
    complete_your_release: IntroductionStep.PENDING,
    complete_track_metadata: IntroductionStep.PENDING,
    test_vinyl_ordered: IntroductionStep.PENDING,
    attach_release_group: IntroductionStep.PENDING,
    release_complete: IntroductionStep.PENDING,
};

export enum INTRODUCTION_GUIDES {
    basic_account_details = 'basic_account_details',
    verify_creator_account = 'verify_creator_account',
    create_first_page = 'create_first_page',
    edit_your_page = 'edit_your_page',
    complete_page = 'complete_page',
    first_page_complete = 'first_page_complete',
    first_page_block = 'first_page_block',
    create_first_release = 'create_first_release',
    complete_your_release = 'complete_your_release',
    complete_track_metadata = 'complete_track_metadata',
    test_vinyl_ordered = 'test_vinyl_ordered',
    attach_release_group = 'attach_release_group',
    release_complete = 'release_complete',
}
