import { INTRODUCTION_GUIDES, INTRODUCTION_INIT_STATE } from '@/consts/introduction';

import UserService from '@/services/UserService';

import { VuexActions } from '@/types/store';
import {
    IntroductionFlowActions,
    IntroductionFlowGetters,
    IntroductionFlowMutations,
    IntroductionFlowState,
    IntroductionStep,
} from '@/types/store/introduction';

export const state: IntroductionFlowState = INTRODUCTION_INIT_STATE;

export const getters: IntroductionFlowGetters = {
    isFlowActive(state: IntroductionFlowState): boolean {
        return state.is_flow_active;
    },
    isProofOrdered(state: IntroductionFlowState): boolean {
        return state.proof_ordered;
    },

    shouldShowForcedGuide: (state: IntroductionFlowState) => (guide: keyof typeof INTRODUCTION_GUIDES) =>
        state.is_flow_active && [IntroductionStep.FORCE].includes(state[guide]),

    shouldShowGuide: (state: IntroductionFlowState) => (guide: keyof typeof INTRODUCTION_GUIDES) =>
        state.is_flow_active && [IntroductionStep.FORCE, IntroductionStep.PENDING].includes(state[guide]),

    hasShownGuide: (state: IntroductionFlowState) => (guide: keyof typeof INTRODUCTION_GUIDES) =>
        state[guide] === IntroductionStep.SHOWN,
};

export const actions: IntroductionFlowActions = {
    setFlowActive({ commit }: VuexActions, isActive: boolean) {
        commit('SET_FLOW_ACTIVE', isActive);
    },
    setProofOrdered({ commit }: VuexActions, isOrdered: boolean) {
        commit('SET_PROOF_ORDERED', isOrdered);
    },
    setGuideShown({ commit }: VuexActions, guide: keyof typeof INTRODUCTION_GUIDES) {
        commit('SET_GUIDE_STATUS', { guide, step: IntroductionStep.SHOWN });
    },
    setGuideStatus(
        { commit }: VuexActions,
        { guide, step }: { guide: keyof typeof INTRODUCTION_GUIDES; step: IntroductionStep },
    ) {
        commit('SET_GUIDE_STATUS', { guide, step });
    },
    async releaseComplete({ rootState }: VuexActions) {
        await UserService.introductionComplete({ uuid: rootState.user.uuid });
    },
};

export const mutations: IntroductionFlowMutations = {
    SET_FLOW_ACTIVE(state: IntroductionFlowState, isActive: boolean) {
        state.is_flow_active = isActive;
    },
    SET_PROOF_ORDERED(state: IntroductionFlowState, isOrdered: boolean) {
        state.proof_ordered = isOrdered;
    },
    SET_GUIDE_STATUS(
        state: IntroductionFlowState,
        { guide, step }: { guide: keyof typeof INTRODUCTION_GUIDES; step: IntroductionStep },
    ) {
        state[guide] = step;
    },
    CLEAR_INTRODUCTION_FLOW(state: IntroductionFlowState) {
        Object.assign(state, INTRODUCTION_INIT_STATE);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
