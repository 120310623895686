import { AxiosResponse } from 'axios';

import { api } from '@/services/ApiService';
import { userMapper } from '@/services/mappers/user.mapper';

import { UserApiResponse } from '@/types/api/user';

export default {
    async fetch(uuid: string) {
        const response: AxiosResponse<{ data: UserApiResponse }> = await api.get(`/api/user/${uuid}`);

        return userMapper(response.data.data);
    },
    async logout() {
        await api.post(`/logout`);
    },
    async introductionComplete({ uuid }: { uuid: string }) {
        await api.post(`/api/users/${uuid}/introduction_flow`);
    },
};
