import UserService from '@/services/UserService';

import { VuexActions } from '@/types/store';
import { UserState } from '@/types/store/user';
import { CreatorData, User } from '@/types/user';

const defaultUserState: UserState = {
    uuid: '',
    user: {
        uuid: '',
        name: '',
        first_name: '',
        email: '',
        is_admin: false,
        is_seller: false,
        records_order_qty_limit: null,
        max_releases_reached: false,
        releases_limit: 0,
        has_2fa: false,
        introduction_complete: false,
        creator_data: null,
    },
};

export const state: UserState = defaultUserState;

export const getters = {
    user(state: UserState): User {
        return state.user;
    },
    uuid(state: UserState): string {
        return state.uuid;
    },
    isLabel(state: UserState): boolean {
        return state.user.is_seller;
    },
    has2fa(state: UserState): boolean {
        return state.user.has_2fa;
    },
    recordsOrderQtyLimit(state: UserState): number {
        return state.user.records_order_qty_limit || 3;
    },
    creatorData(state: UserState): CreatorData | null {
        return state.user.creator_data;
    },
    isIntroductionComplete(state: UserState): boolean {
        return state.user.introduction_complete || false;
    },
};

export const actions = {
    async fetch({ commit }: VuexActions, uuid: string) {
        const response = await UserService.fetch(uuid);

        commit('SET_USER', response);
    },
    async logout({ commit }: VuexActions) {
        await UserService.logout();

        commit('DESTROY_USER');

        window.location = '/' as unknown as Location;
    },
    setUserUuid({ commit }: VuexActions, uuid: string) {
        commit('SET_USER_UUID', uuid);
    },
};

export const mutations = {
    SET_USER(state: UserState, user: User) {
        state.user = user;
    },
    SET_USER_UUID(state: UserState, uuid: string) {
        state.uuid = uuid;
    },
    DESTROY_USER(state: UserState) {
        Object.assign(state, defaultUserState);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
