import { ActionContext } from 'vuex';

import { INTRODUCTION_GUIDES } from '@/consts/introduction';

export interface IntroductionFlowState {
    is_flow_active: boolean;
    proof_ordered: boolean;

    basic_account_details: IntroductionStep;
    verify_creator_account: IntroductionStep;

    create_first_page: IntroductionStep;
    edit_your_page: IntroductionStep;
    complete_page: IntroductionStep;
    first_page_complete: IntroductionStep;
    first_page_block: IntroductionStep;

    create_first_release: IntroductionStep;
    complete_your_release: IntroductionStep;
    complete_track_metadata: IntroductionStep;

    release_complete: IntroductionStep;

    test_vinyl_ordered: IntroductionStep;
    attach_release_group: IntroductionStep;
}

export enum IntroductionStep {
    PENDING = 'PENDING',
    SHOWN = 'SHOWN',
    FORCE = 'FORCE',
}

export interface IntroductionFlowGetters {
    isFlowActive(state: IntroductionFlowState): boolean;
    isProofOrdered(state: IntroductionFlowState): boolean;
    shouldShowForcedGuide(state: IntroductionFlowState): any;
    shouldShowGuide(state: IntroductionFlowState): any;
    hasShownGuide(state: IntroductionFlowState): any;
}

export interface IntroductionFlowActions {
    setFlowActive(context: ActionContext<IntroductionFlowState, unknown>, isActive: boolean): void;
    setProofOrdered(context: ActionContext<IntroductionFlowState, unknown>, proofOrdered: boolean): void;
    setGuideShown(
        context: ActionContext<IntroductionFlowState, unknown>,
        guide: keyof typeof INTRODUCTION_GUIDES,
    ): void;
    setGuideStatus(
        context: ActionContext<IntroductionFlowState, unknown>,
        data: { guide: keyof typeof INTRODUCTION_GUIDES; step: IntroductionStep },
    ): void;
    releaseComplete(context: ActionContext<IntroductionFlowState, unknown>): void;
}

export interface IntroductionFlowMutations {
    SET_FLOW_ACTIVE(state: IntroductionFlowState, isActive: boolean): void;
    SET_PROOF_ORDERED(state: IntroductionFlowState, isOrdered: boolean): void;
    SET_GUIDE_STATUS(
        state: IntroductionFlowState,
        data: {
            guide: keyof typeof INTRODUCTION_GUIDES;
            step: IntroductionStep;
        },
    ): void;
    CLEAR_INTRODUCTION_FLOW(state: IntroductionFlowState): void;
}
