import { Ref, UnwrapRef } from 'vue';

interface ModalMethods {
    show: () => void;
    hide: () => void;
}

type ModalRefs = Record<string, Ref<UnwrapRef<ModalMethods | null>>>;

export default function useModalAware(refs: ModalRefs) {
    function show(modal: string): void {
        if (refs[modal].value?.show) {
            refs[modal].value?.show();
        }
    }

    function hide(modal: string): void {
        if (refs[modal].value?.hide) {
            refs[modal].value?.hide();
        }
    }

    return { show, hide };
}
