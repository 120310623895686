import { computed } from 'vue';
import { useStore } from 'vuex';

import { IntroductionStep } from '@/types/store/introduction';

export function useReleaseBlock() {
    const store = useStore();

    const isFlowActive = computed(() => store.getters['introduction/isFlowActive']);
    const hasShownFirstPageCompleteGuide = computed(() =>
        store.getters['introduction/hasShownGuide']('first_page_complete'),
    );

    const checkReleaseBlock = (event: MouseEvent) => {
        event.preventDefault();

        const target = event.currentTarget as HTMLAnchorElement;

        if (isFlowActive.value && !hasShownFirstPageCompleteGuide.value) {
            store.dispatch('introduction/setGuideStatus', {
                guide: 'first_page_block',
                step: IntroductionStep.FORCE,
            });
        } else {
            window.location.href = target.href;
        }
    };

    return {
        checkReleaseBlock,
    };
}
